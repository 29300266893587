import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DATA_SOURCES } from 'constants/appDataSources';
import { setAppDataSourceId, setAppDataSourceAppConfig } from 'store/features/appDataSourceSlice';
import useAppDataSource from 'hooks/useAppDataSource';
import callAzureFunction from 'utils/call-azure-function';
import handleError from 'utils/handle-error';

const DataSourceMenu = () => {
  const dispatch = useDispatch();
  const { appDataSourceId, appDataSourceImplemented, appDataSourceDisabled, useGetAppDataSources, isAppDataSourceRemote } =
    useAppDataSource();
  const appDataSources = useGetAppDataSources();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dataSources, setDataSources] = useState(Object.values(DATA_SOURCES));
  useEffect(() => {
    setDataSources(Object.values(appDataSources));
  }, [appDataSources]);

  const getRemoteAppConfig = useCallback(async () => {
    try {
      const response = await callAzureFunction({
        url: `public/app-configurations`,
        method: 'get',
        appDataSourceId
      });
      dispatch(setAppDataSourceAppConfig(JSON.parse(response.data.config)));
    } catch (error) {
      handleError(error);
    }
  }, [appDataSourceId, dispatch]);

  useEffect(() => {
    if (isAppDataSourceRemote) {
      getRemoteAppConfig();
    }
  }, [isAppDataSourceRemote, getRemoteAppConfig]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeDataSource = (source) => {
    dispatch(setAppDataSourceId(source.id));
    handleClose();
  };

  const appDataSourceDefault = appDataSources.default || DATA_SOURCES.default;

  return (
    <>
      {dataSources.length > 1 ? (
        <div>
          <Button
            id="data-source-button"
            aria-controls={open ? 'data-source-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpen}
            endIcon={<ExpandMoreIcon />}
            disabled={appDataSourceDisabled || !appDataSourceImplemented}
          >
            <span>
              {!appDataSourceImplemented
                ? appDataSourceDefault.name
                : dataSources.find((source) => source.id === appDataSourceId)?.name || 'N/A'}
            </span>
          </Button>
          <Menu
            id="data-source-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'data-source-button'
            }}
          >
            {dataSources.map((source) => (
              <MenuItem key={source.id} onClick={() => handleChangeDataSource(source)} selected={source.id === appDataSourceId}>
                <span style={{ textTransform: 'capitalize' }}>{source.name}</span>
              </MenuItem>
            ))}
          </Menu>
        </div>
      ) : null}
    </>
  );
};

export default DataSourceMenu;
