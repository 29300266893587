import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { DATA_SOURCE_ROUTES, DATA_SOURCES } from 'constants/appDataSources';

const useAppDataSource = () => {
  const location = useLocation();
  const appDataSource = useSelector((state) => state?.appDataSource) || {};

  const { appDataSourceImplemented, appDataSourceDisabled } = useMemo(
    () =>
      DATA_SOURCE_ROUTES.reduce(
        (result, route) => {
          if (route.path.test(location.pathname)) {
            result.appDataSourceImplemented = true;
            if (route.disabled) {
              result.appDataSourceDisabled = true;
            }
          }
          return result;
        },
        { appDataSourceImplemented: false, appDataSourceDisabled: false }
      ),
    [location.pathname]
  );

  const appDataSourceId = appDataSource?.selectedId || DATA_SOURCES.default.id;
  const appDataSourceUrl = appDataSource?.dataSources[appDataSource?.selectedId]?.url || DATA_SOURCES.default.url;
  const appDataSourceWebsite = appDataSource?.dataSources[appDataSource?.selectedId]?.website || DATA_SOURCES.default.website;
  const isAppDataSourceRemote = appDataSourceId && appDataSourceId !== 'default';
  const useGetAppDataSources = () => useSelector((state) => state?.appDataSource?.dataSources);
  const useGetRemoteAppConfig = () => useSelector((state) => state?.appDataSource?.appConfig);

  return {
    appDataSource,
    appDataSourceId,
    appDataSourceUrl,
    appDataSourceWebsite,
    appDataSourceImplemented,
    appDataSourceDisabled: appDataSourceDisabled || Boolean(appDataSource.disabled),
    isAppDataSourceRemote,
    useGetAppDataSources,
    useGetRemoteAppConfig
  };
};

export default useAppDataSource;
