import { createSlice } from '@reduxjs/toolkit';
import { DATA_SOURCES } from 'constants/appDataSources';
import { defaultAppConfig } from 'constants/appConfig';

export const initialState = {
  selectedId: DATA_SOURCES.default.id,
  dataSources: DATA_SOURCES,
  disabled: false,
  appConfig: defaultAppConfig
};

const appDataSourceSlice = createSlice({
  name: 'appDataSource',
  initialState,
  reducers: {
    setAppDataSourceId: (state, action) => {
      state.selectedId = action.payload;
    },
    setAppDataSources: (state, action) => {
      state.dataSources = action.payload;
    },
    setAppDataSourceDisabled: (state, action) => {
      state.disabled = action.payload;
    },
    setAppDataSourceAppConfig: (state, action) => {
      state.appConfig = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAppDataSourceId, setAppDataSources, setAppDataSourceDisabled, setAppDataSourceAppConfig } = appDataSourceSlice.actions;

export default appDataSourceSlice.reducer;
