class Route {
  constructor(path, disabled = false) {
    this.path = path;
    this.disabled = disabled;
  }
}

/* eslint-disable import/prefer-default-export */
const DATA_SOURCES = {
  default: {
    id: 'default',
    name: 'Local (Aspen)',
    url: import.meta.env.VITE_APP_AZ_FUNCTIONS_BASE_URI,
    website: import.meta.env.VITE_APP_WEB_APP_BASE_URL
  }
};

const DATA_SOURCE_ROUTES = [
  new Route(/\/dashboard-booking-stats/),
  new Route(/\/dashboard-survey-stats/),
  new Route(/\/respondents/),
  new Route(/\/respondents\/view-respondent\/\d+/, true),
  new Route(/\/respondents\/edit-respondent\/\d+/, true),
  new Route(/\/manage-booking-pages/),
  new Route(/\/manage-booking-pages\/booking-page/, true),
  new Route(/\/manage-booking-pages\/edit-booking-page\/\d+/, true),
  new Route(/\/manage-bookings/)
];

export { DATA_SOURCES, DATA_SOURCE_ROUTES };
