import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const SurveyAnswerPage = Loadable(lazy(() => import('views/surveys/survey-answer')));
const DownloadSurveyAnswer = Loadable(lazy(() => import('views/shared/download-survey-answer')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const childRoutes = [
  {
    path: '/surveys/:surveyTypeUID',
    element: <SurveyAnswerPage />
  },
  {
    path: '/surveys/survey/:surveyUID',
    element: <SurveyAnswerPage />
  },
  {
    path: '/surveys/:surveyId/download',
    element: <DownloadSurveyAnswer />
  }
];

const SurveyRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: childRoutes
};

export default SurveyRoutes;
